import { makeStyles, mergeClasses, shorthands, Text, tokens } from '@fluentui/react-components';
import {
    BotSparkle24Regular,
    Cloud24Regular,
    CloudArchive24Regular,
    FluentIcon,
    People24Regular,
    ShieldTask16Regular,
} from '@fluentui/react-icons';
import { FC, useState } from 'react';
import Typewriter from 'typewriter-effect';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { FeatureKeys } from '../../../redux/features/app/AppState';
import {
    resetRecentAutoRenameStatus,
    setSelectedConversation,
} from '../../../redux/features/conversations/conversationsSlice';
import { Breakpoints, SharedStyles } from '../../../styles';
import { timestampToDateString } from '../../utils/TextUtils';
import { EditChatName } from '../shared/EditChatName';
import { ListItemActions } from './ListItemActions';

const useClasses = makeStyles({
    root: {
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        ...Breakpoints.small({
            justifyContent: 'center',
        }),
        cursor: 'pointer',
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalXL),
    },
    avatar: {
        flexShrink: 0,
        width: '32px',
        display: 'flex',
        alignSelf: 'end',
    },
    body: {
        minWidth: 0,
        width: '100%',
        display: 'flex',
        marginLeft: tokens.spacingHorizontalXS,
        alignSelf: 'center',
        flexDirection: 'column',
        height: '32px',
    },
    chatLabel: {
        color: tokens.colorNeutralForeground2,
        ...shorthands.borderRadius('5px'),
        ...shorthands.padding('0', '10px', '0px'),
        backgroundColor: tokens.colorBrandBackground2,
        fontSize: tokens.fontSizeBase200,
        fontWeight: 600,
        width: 'fit-content',
    },
    header: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        ...SharedStyles.overflowEllipsis,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralForeground1,
        lineHeight: '20px',
    },
    timestamp: {
        flexShrink: 0,
        marginLeft: tokens.spacingHorizontalM,
        fontSize: tokens.fontSizeBase200,
        color: tokens.colorNeutralForeground2,
        lineHeight: tokens.lineHeightBase200,
    },
    previewText: {
        ...SharedStyles.overflowEllipsis,
        display: 'block',
        lineHeight: tokens.lineHeightBase100,
        color: tokens.colorNeutralForeground2,
    },
    popoverSurface: {
        display: 'none',
        ...Breakpoints.small({
            display: 'flex',
            flexDirection: 'column',
        }),
    },
    selected: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    protectedIcon: {
        color: tokens.colorPaletteLightGreenBorder1,
        verticalAlign: 'text-bottom',
        marginLeft: tokens.spacingHorizontalXS,
    },
});

interface IChatListItemProps {
    id: string;
    header: string;
    timestamp: number;
    recentAutoRename: boolean;
    preview: string | undefined;
    isSelected: boolean;
    isHidden: boolean;
    scope?: string;
}

export const ChatListItem: FC<IChatListItemProps> = ({
    id,
    header,
    timestamp,
    recentAutoRename,
    preview,
    isSelected,
    isHidden,
    scope,
}) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { features } = useAppSelector((state: RootState) => state.app);

    const showPreview = !features[FeatureKeys.SimplifiedExperience].enabled && preview;
    const showActions = features[FeatureKeys.SimplifiedExperience].enabled && isSelected;

    const [editingTitle, setEditingTitle] = useState(false);

    const onClick = (_ev: any) => {
        dispatch(setSelectedConversation(id));
    };

    const time = timestampToDateString(timestamp);

    const onTypingComplete = () => {
        dispatch(resetRecentAutoRenameStatus({ chatId: id }));
    };

    let ScopeIcon: FluentIcon = BotSparkle24Regular;
    switch (scope?.split(':')[0].toLowerCase()) {
        case 'web':
        case 'recommendedsites':
            ScopeIcon = Cloud24Regular;
            break;
        case 'authdocs':
            ScopeIcon = CloudArchive24Regular;
            break;
        case 'spo':
            ScopeIcon = People24Regular;
            break;
    }

    return (
        <div
            className={mergeClasses(classes.root, isSelected && classes.selected)}
            onClick={onClick}
            title={`Chat: ${header}`}
            aria-label={`Chat list item: ${header}`}
        >
            <div className={classes.avatar}>
                <ScopeIcon />
            </div>
            {editingTitle ? (
                <EditChatName
                    name={header}
                    chatId={id}
                    exitEdits={() => {
                        setEditingTitle(false);
                    }}
                />
            ) : (
                <>
                    <div className={classes.body}>
                        {isHidden && <Text className={classes.chatLabel}>Hidden</Text>}
                        <div className={classes.header}>
                            <Text className={classes.title} title={header}>
                                {recentAutoRename ? (
                                    <Typewriter
                                        options={{ delay: 20 }}
                                        onInit={(typewriter) => {
                                            typewriter
                                                .typeString(header)
                                                .callFunction(() => {
                                                    onTypingComplete();
                                                })
                                                .start();
                                        }}
                                    />
                                ) : (
                                    header
                                )}
                                {features[FeatureKeys.AzureContentSafety].enabled && (
                                    <ShieldTask16Regular className={classes.protectedIcon} />
                                )}
                            </Text>
                            {!features[FeatureKeys.SimplifiedExperience].enabled && (
                                <Text className={classes.timestamp} size={300}>
                                    {time}
                                </Text>
                            )}
                        </div>
                        {showPreview && (
                            <>
                                {
                                    <Text id={`message-preview-${id}`} size={200} className={classes.previewText}>
                                        {preview}
                                    </Text>
                                }
                            </>
                        )}
                    </div>
                    {showActions && (
                        <ListItemActions
                            chatId={id}
                            chatName={header}
                            isHidden={isHidden}
                            onEditTitleClick={() => {
                                setEditingTitle(true);
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
};
