import { makeStyles, shorthands, Text, tokens } from '@fluentui/react-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ChatMessageType } from '../../../libs/models/ChatMessage';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { IConversationDisplayMode } from '../../../redux/features/app/AppState';
import { Conversations } from '../../../redux/features/conversations/ConversationsState';
import { Breakpoints } from '../../../styles';
import { ChatListItem } from './ChatListItem';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap(tokens.spacingVerticalXXS),
        paddingBottom: tokens.spacingVerticalXS,
    },
    header: {
        ...shorthands.borderBottom('1px', 'solid', 'rgb(189, 189, 189)'),
        ...shorthands.padding('2px', '5px', '5px', '5px'),
        marginTop: 0,
        marginBottom: '5px',
        paddingBottom: tokens.spacingVerticalXS,
        marginLeft: tokens.spacingHorizontalXL,
        marginRight: tokens.spacingHorizontalXL,
        fontWeight: tokens.fontWeightBold,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorNeutralForeground3,
        ...Breakpoints.small({
            display: 'none',
        }),
    },
});

interface IChatListSectionProps {
    onSelectChat?: () => void;
    header?: string;
    conversations: Conversations;
    fetchData?: () => void;
    hasMore?: boolean;
}

export const ChatListSection: React.FC<IChatListSectionProps> = ({
    // onSelectChat,
    header,
    conversations,
    fetchData,
    hasMore,
}) => {
    const classes = useClasses();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { conversationDisplayMode } = useAppSelector((state: RootState) => state.app);
    const keys = Object.keys(conversations);
    const hasNonHiddenConversations = keys.some((id) => !conversations[id].hidden);
    const hasHiddenConversations = keys.some((id) => conversations[id].hidden);

    return keys.length > 0 && fetchData && hasMore !== undefined ? (
        <div className={classes.root}>
            {(conversationDisplayMode === IConversationDisplayMode.All ||
                (conversationDisplayMode === IConversationDisplayMode.Active && hasNonHiddenConversations) ||
                (conversationDisplayMode === IConversationDisplayMode.Hidden && hasHiddenConversations)) && (
                <Text className={classes.header}>{header}</Text>
            )}

            <InfiniteScroll
                dataLength={keys.length}
                next={fetchData}
                hasMore={hasMore}
                scrollableTarget="chat-list"
                loader={null}
            >
                {keys.map((id) => {
                    const convo = conversations[id];
                    let lastMessage;
                    let preview = undefined;
                    if (convo.messages) {
                        lastMessage = convo.messages[convo.messages.length - 1];
                        preview =
                            convo.messages.length > 0
                                ? lastMessage.type === ChatMessageType.Document
                                    ? 'Sent a file'
                                    : lastMessage.type === ChatMessageType.Plan
                                      ? 'Click to view proposed plan'
                                      : lastMessage.content
                                : 'Click to start the chat';
                    }

                    const isSelected = id === selectedId;
                    const chatListItem = (
                        <ChatListItem
                            id={id}
                            key={id}
                            isSelected={isSelected}
                            header={convo.title}
                            timestamp={convo.lastUpdatedTimestamp ?? lastMessage?.timestamp ?? 0}
                            recentAutoRename={convo.recentAutoRename ?? false}
                            preview={preview}
                            isHidden={convo.hidden ?? false}
                            scope={convo.chatScope}
                        />
                    );

                    if (
                        (conversationDisplayMode === IConversationDisplayMode.Active && !convo.hidden) ||
                        (conversationDisplayMode === IConversationDisplayMode.Hidden && convo.hidden)
                    ) {
                        return chatListItem;
                    } else if (conversationDisplayMode === IConversationDisplayMode.All) {
                        return chatListItem;
                    } else {
                        return null;
                    }
                })}
            </InfiniteScroll>
        </div>
    ) : null;
};
