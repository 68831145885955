import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../auth/AuthHelper';
import { AuthoritiveService, IBucket, IBucketSource } from '../services/AuthoritiveService';
import { IAppConfigResult, useAppConfig } from './useAppConfig';
import { IAppDropdownConfigValue } from '../services/AppConfigService';

export interface IResult<T> {
    Data?: T;
    Success: boolean;
    Message?: string;
}

export const useAuthoritive = () => {
    const { instance, inProgress } = useMsal();

    const appConfig = useAppConfig();
    const authoritiveService = new AuthoritiveService();

    const getBuckets = async (user: string): Promise<IResult<IBucket[]>> => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        try {
            const value = await authoritiveService.getBucketsAsync(accessToken, {
                user,
                useBrowserCache: false,
            });
            return { Data: value, Success: true };
        } catch (e: any) {
            const errorMessage = `Error getting buckets. Details: ${getErrorDetails(e)}`;
            return { Success: false, Message: errorMessage };
        }
    };

    const getBucketSources = async (user: string): Promise<IResult<IBucketSource[]>> => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);

        try {
            const value = await authoritiveService.getBucketSourcesAsync(accessToken, {
                user,
                useBrowserCache: false,
            });
            return { Data: value, Success: true };
        } catch (e: any) {
            const errorMessage = `Error getting buckets. Details: ${getErrorDetails(e)}`;
            return { Success: false, Message: errorMessage };
        }
    };

    const getAuthDocsScopeName = async (user: string): Promise<string> => {
        const scopeOptions: IAppConfigResult<IAppDropdownConfigValue[]> = await appConfig.getConfigDropdownValues(user);

        if (scopeOptions.Success && scopeOptions.Data) {
            return scopeOptions.Data.find(item => item.name.startsWith('AuthDocs:'))?.name ?? '';
        } else {
            return '';
        }
    };

    return {
        getBuckets,
        getBucketSources,
        getAuthDocsScopeName
    };
};

function getErrorDetails(e: any) {
    return e instanceof Error ? e.message : String(e);
}
