import type { PopoverProps, PositioningImperativeRef } from '@fluentui/react-components';
import { Input, Popover, PopoverSurface, makeStyles } from '@fluentui/react-components';
import { SearchRegular } from '@fluentui/react-icons';
import debug from 'debug';
import * as React from 'react';
import { FC } from 'react';
import { Constants } from '../../../Constants';
import { IResult, useAuthoritive } from '../../../libs/hooks/useAuthoritive';
import { IBucket, IBucketSource } from '../../../libs/services/AuthoritiveService';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { BucketItem, BucketType } from '../../../redux/features/buckets/BucketsState';
import { setBuckets } from '../../../redux/features/buckets/bucketsSlice';
import { BucketSelectionTree } from './BucketSelectionTree';
interface BucketSelectionMenuProps {
    chatId: string;
    isOpen: boolean;
    targetRef: React.RefObject<HTMLTextAreaElement>;
    onChange: (value: boolean) => void;
}

const useStyles = makeStyles({
    contentHeader: {
        marginTop: '0',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
});

export const BucketSelectionMenu: FC<BucketSelectionMenuProps> = ({ chatId, isOpen, targetRef, onChange }) => {
    const styles = useStyles();
    const positioningRef = React.useRef<PositioningImperativeRef>(null);
    const [searchText, setSearchText] = React.useState<string>('');
    //const [bucketItems, setBucketItems] = React.useState<BucketItem[]>([]);
    const [filteredItems, setFilteredItems] = React.useState<BucketItem[]>([]);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const dispatch = useAppDispatch();
    const { Buckets } = useAppSelector((state: RootState) => state.buckets);
    const emailAddress = activeUserInfo?.email ?? '';
    const user = `${emailAddress}`;
    const authoritiveService = useAuthoritive();
    const log = debug(Constants.debug.root).extend('bucket-selection-menu');

    const handleOpenChange: PopoverProps['onOpenChange'] = (e, data) => {
        void e;
        onChange(data.open || false);
        setSearchText('');
        setFilteredItems(Buckets);
    };

    const mapBuckets = (buckets: IBucket[]): BucketItem[] => {
        return buckets.map(
            (b) =>
                ({
                    id: b.Id,
                    title: b.Title,
                    color: b.Color,
                    type: BucketType.Bucket,
                }) as BucketItem,
        );
    };

    const mapBucketSources = (buckets: BucketItem[], sources: IBucketSource[]): BucketItem[] => {
        return sources.map(
            (s) =>
                ({
                    id: s.DocumentId,
                    title: s.Title,
                    type: BucketType.BucketSource,
                    url: s.DocumentUrl,
                    parent: buckets.find((b) => b.id === s.BucketId),
                }) as BucketItem,
        );
    };

    React.useEffect(() => {
        if (targetRef.current) {
            positioningRef.current?.setTarget(targetRef.current);
        }
    }, [targetRef, positioningRef]);

    const getBuckets = async () => {
        const bucketsResponse: IResult<IBucket[]> = await authoritiveService.getBuckets(user);
        const bucketSourcesResponse: IResult<IBucketSource[]> = await authoritiveService.getBucketSources(user);

        if (bucketsResponse.Success && bucketSourcesResponse.Success) {
            const buckets = bucketsResponse.Data;
            const bucketSources = bucketSourcesResponse.Data;
            const filteredBuckets = buckets?.filter((b) => bucketSources?.some((i) => i.BucketId === b.Id));
            const bucketList: BucketItem[] = [];

            if (filteredBuckets?.length) {
                const mappedBuckets = mapBuckets(filteredBuckets);
                bucketList.push(...mappedBuckets);
                if (bucketSources?.length) {
                    const mappedBucketSources = mapBucketSources(mappedBuckets, bucketSources);
                    bucketList.push(...mappedBucketSources);
                }
            }

            dispatch(setBuckets(bucketList));
            //setBucketItems(bucketList);
            setFilteredItems(bucketList);
        }
    };

    React.useEffect(() => {
        getBuckets().catch((e) => {
            log('error getting splash guidance', e);
        });
        //const mockService = new MockDataService();
        //setBucketItems(mockService.GetBuckets());
        //setFilteredItems(mockService.GetBuckets());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setFilteredItems(Buckets);
    }, [Buckets]);

    React.useEffect(() => {
        if (searchText) {
            const searchItems = Buckets.filter((d) => d.title.toLowerCase().includes(searchText.toLowerCase()));
            const newFilteredItems = searchItems.map((i) => ({
                ...i,
                parent: undefined,
            }));
            setFilteredItems(newFilteredItems);
        }
        if (searchText === '') {
            setFilteredItems(Buckets);
        }
    }, [Buckets, searchText]);

    return (
        <div className={styles.container}>
            <Popover open={isOpen} onOpenChange={handleOpenChange} positioning={{ positioningRef, align: 'start' }}>
                <PopoverSurface tabIndex={-1}>
                    <Input
                        value={searchText}
                        onChange={(e, d) => {
                            void e;
                            setSearchText(d.value);
                        }}
                        contentBefore={<SearchRegular />}
                        placeholder="Type to search for buckets or files"
                        style={{ width: '400px' }}
                    />
                    {filteredItems.length && <BucketSelectionTree chatId={chatId} items={filteredItems} />}
                </PopoverSurface>
            </Popover>
        </div>
    );
};
