import {
    FlatTree,
    FlatTreeItem,
    HeadlessFlatTreeItemProps,
    TreeCheckedChangeData,
    TreeItemPersonaLayout,
    mergeCallbacks,
    useHeadlessFlatTree_unstable,
} from '@fluentui/react-components';
import { FolderFilled, DocumentBulletListRegular } from '@fluentui/react-icons';
import { FC } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { BucketItem, BucketType } from '../../../redux/features/buckets/BucketsState';
import { updateBucket } from '../../../redux/features/buckets/bucketsSlice';
import { resetIntelligentFilteringMessage } from '../../../redux/features/conversations/conversationsSlice';

const SELECTION_MODE = 'multiselect'; // change to "single" for single selection

type CustomItem = HeadlessFlatTreeItemProps & { content: string; item: BucketItem };

export interface IBucketSelectionTreeProps {
    chatId: string;
    items: BucketItem[];
}

export const BucketSelectionTree: FC<IBucketSelectionTreeProps> = ({ chatId, items }) => {
    const { Buckets } = useAppSelector((state: RootState) => state.buckets);
    const dispatch = useAppDispatch();

    const mapBucketItems = (buckets: BucketItem[]) => {
        return buckets.map(
            (b) =>
                ({
                    value: b.id,
                    content: b.title,
                    parentValue: b.parent?.id,
                    item: b,
                }) as CustomItem,
        );
    };

    const flatTree = useHeadlessFlatTree_unstable(mapBucketItems(items), {
        defaultCheckedItems: Buckets.filter(b=> b.selected).map(b=> b.id),
        selectionMode: SELECTION_MODE,
    });

    const onItemCheckedChange = (event: React.ChangeEvent<HTMLElement>, treeData: TreeCheckedChangeData) => {
        void event;
        const bucketItem = Buckets.find((d) => d.id === treeData.value);

        if (bucketItem) {
            dispatch(resetIntelligentFilteringMessage({ chatId }));
            dispatch(updateBucket({ ...bucketItem, selected: Boolean(treeData.checked) }));
        }
    };

    const mergedOnChange = mergeCallbacks(flatTree.getTreeProps().onCheckedChange, onItemCheckedChange);

    return (
        <FlatTree
            {...flatTree.getTreeProps()}
            aria-label="Selection"
            style={{ width: '400px' }}
            onCheckedChange={mergedOnChange}
        >
            {Array.from(flatTree.items(), (flatTreeItem) => {
                const { content, item, ...treeItemProps } = flatTreeItem.getTreeItemProps();
                return (
                    <FlatTreeItem {...treeItemProps} key={flatTreeItem.value}>
                        <TreeItemPersonaLayout
                            media={
                                item.type === BucketType.Bucket ? (
                                    <FolderFilled fontSize={25} color={item.color} />
                                ) : (
                                    <DocumentBulletListRegular fontSize={25} />
                                )
                            }
                        >
                            {content}
                        </TreeItemPersonaLayout>
                    </FlatTreeItem>
                );
            })}
        </FlatTree>
    );
};
