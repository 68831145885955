import { Button } from '@fluentui/react-button';
import { Tooltip, makeStyles } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { useChat } from '../../../../libs/hooks';
import { AlertType } from '../../../../libs/models/AlertType';
import { useAppDispatch, useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';
import { IConversationDisplayMode } from '../../../../redux/features/app/AppState';
import { addAlert } from '../../../../redux/features/app/appSlice';
import { ConversationHiddenStatusChange } from '../../../../redux/features/conversations/ConversationsState';
import {
    setConversationAsHidden,
    setSelectedConversation,
} from '../../../../redux/features/conversations/conversationsSlice';
import { ArrowHookUpLeft16 } from '../../../shared/BundledIcons';

const useClasses = makeStyles({
    root: {
        width: '450px',
    },
    actions: {
        paddingTop: '10%',
    },
});

interface IRestoreChatProps {
    chatId: string;
    chatName: string;
}

export const RestoreChatDialog: React.FC<IRestoreChatProps> = ({ chatName, chatId }) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { conversationDisplayMode } = useAppSelector((state: RootState) => state.app);
    const chat = useChat();

    const onRestoreChat = async () => {
        const chatState = conversations[selectedId];
        await chat
            .editChat(chatId, chatName, chatState.memoryBalance, chatState.chatScope, [], false)
            .then(() => {
                dispatch(
                    setConversationAsHidden({ id: chatId, newHiddenState: false } as ConversationHiddenStatusChange),
                );
                if (conversationDisplayMode !== IConversationDisplayMode.All) {
                    dispatch(setSelectedConversation(''));
                }
            })
            .catch((e: any) => {
                const errorMessage = e instanceof Error ? e.message : String(e);
                throw new Error(errorMessage);
            });
    };

    const handleRestoreChat = () => {
        onRestoreChat().catch((e: any) => {
            const errorMessage = `Unable to restore chat. Details: ${e instanceof Error ? e.message : String(e)}`;
            dispatch(addAlert({ message: errorMessage, type: AlertType.Error }));
        });
    };

    return (
        <Dialog modalType="alert">
            <DialogTrigger>
                <Tooltip content={'Restore chat session'} relationship="label">
                    <Button icon={<ArrowHookUpLeft16 />} appearance="transparent" aria-label="Edit" />
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle>Are you sure you want to restore conversation &quot;{chatName}&quot;</DialogTitle>
                    <DialogContent>Would you like to restore this conversation?</DialogContent>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button
                                appearance="primary"
                                style={{ width: '180px' }}
                                onClick={() => {
                                    handleRestoreChat();
                                }}
                            >
                                Restore Conversation
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
