import {
    Button,
    makeStyles,
    shorthands,
    MessageBar,
    MessageBarActions,
    MessageBarBody,
    MessageBarTitle,
} from '@fluentui/react-components';
import { DismissRegular, OptionsRegular } from '@fluentui/react-icons';
import { customTokens } from '../../../styles';

import React from 'react';
import { useChat } from '../../../libs/hooks';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { setBuckets, updateBucket } from '../../../redux/features/buckets/bucketsSlice';
import { editConversationScope, hideIntelligentFilteringMessage } from '../../../redux/features/conversations/conversationsSlice';
import { useAuthoritive } from '../../../libs/hooks/useAuthoritive';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding('15px 0px 0px 45px'),
        position: 'relative',
    },
    messageBar: {
        backgroundColor: customTokens.colorNeutralBackground1,
    },
});

export const IntelligentFilteringMessage: React.FC = () => {
    const classes = useClasses();
    const { Buckets } = useAppSelector((state) => state.buckets);
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const chat = useChat();

    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const emailAddress = activeUserInfo?.email ?? '';
    const user = emailAddress;

    const authoritive = useAuthoritive();

    const suggestedBucket = Buckets.find((b) => b.id === conversations[selectedId].intelligentFilteringBucketId);

    if (!suggestedBucket) {
        return null;
    }

    const handleDismiss = () => {
        dispatch(hideIntelligentFilteringMessage({ chatId: selectedId }));
    };

    const handleChangeScope = async () => {
        dispatch(hideIntelligentFilteringMessage({ chatId: selectedId }));
        const chatState = conversations[selectedId];
        const authoritiveDocuments = Buckets.filter((b) => b.parent?.id === suggestedBucket.id).map((b) => b.id);
        const authScopeName = await authoritive.getAuthDocsScopeName(user);

        if (!authScopeName) {
            console.error('No AuthDocs scope found');
            return;
        }

        await chat
            .editChat(
                chatState.id,
                chatState.title,
                chatState.memoryBalance,
                authScopeName,
                chatState.selectedSites,
                chatState.hidden,
                authoritiveDocuments,
            )
            .then(() => {
                localStorage.setItem('lastSelectedChatScope', "AuthDocs:global-documents");
                dispatch(setBuckets(Buckets.map((b) => ({ ...b, selected: false }))));
                dispatch(updateBucket({ ...suggestedBucket, selected: true }));
                dispatch(editConversationScope({ id: selectedId, newScope: authScopeName }));
            }).catch((e: any) => {
                const errorMessage = `Unable to change chat scope. Details: ${
                    e instanceof Error ? e.message : String(e)
                    }`;
                console.error(errorMessage);
            });
    }

    return (
        <div className={classes.root}>
            <MessageBar className={classes.messageBar} layout="multiline">
                <MessageBarBody>
                    <MessageBarTitle>Intelligent Filtering</MessageBarTitle>
                    &nbsp;&nbsp; For best accuracy, authoritative &apos;{suggestedBucket.title}&apos; documents were
                    used to answer your question. Click &apos;Keep Scope&apos; if you intend to ask similar follow-up questions.&nbsp;&nbsp;
                </MessageBarBody>
                <MessageBarActions
                    containerAction={
                        <Button
                            appearance="transparent"
                            icon={<DismissRegular />}
                            onClick={() => {
                                handleDismiss();
                            }}
                        />
                    }
                >
                    <Button icon={<OptionsRegular />} onClick={() => void handleChangeScope()}>
                        Keep scope
                    </Button>
                </MessageBarActions>
            </MessageBar>
        </div>
    );
};
