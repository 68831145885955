/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import CacheUtils from '../utils/CacheUtils';
import { BaseService, getCacheQueryString } from './BaseService';

export interface IUseBrowserCache {
    user: string;
    useBrowserCache: boolean;
}

export interface IBucket {
    Id: string;
    Title: string;
    Color?: string;
}

export interface IBucketSource extends IBucket {
    DocumentUrl: string;
    DocumentId: string;
    BucketId?: string;
}

export class AuthoritiveService extends BaseService {
    public getBucketsAsync = async (accessToken: string, browserCache: IUseBrowserCache): Promise<IBucket[]> => {
        const dataCacheKey = browserCache.user ? `getBucketsAsync.${browserCache.user}` : '';
        const cachedData: any =
            browserCache.useBrowserCache && dataCacheKey ? CacheUtils.getStoredDataByKey(dataCacheKey) : null;

        if (cachedData) {
            return cachedData;
        } else {
            const result = await this.getResponseAsync<IBucket[]>(
                {
                    commandPath: `authSources/getBuckets${getCacheQueryString('clearCache', '?')}`,
                    method: 'GET',
                },
                accessToken,
            );

            if (browserCache.useBrowserCache && dataCacheKey) {
                CacheUtils.setStoredDataByKey(dataCacheKey, result, CacheUtils.dataCacheLongExpiresMin);
            }

            return result;
        }
    };

    public getBucketSourcesAsync = async (
        accessToken: string,
        browserCache: IUseBrowserCache,
    ): Promise<IBucketSource[]> => {
        const dataCacheKey = browserCache.user ? `getBucketSourcesAsync.${browserCache.user}` : '';
        const cachedData: any =
            browserCache.useBrowserCache && dataCacheKey ? CacheUtils.getStoredDataByKey(dataCacheKey) : null;

        if (cachedData) {
            return cachedData;
        } else {
            const result = await this.getResponseAsync<IBucketSource[]>(
                {
                    commandPath: `authSources/getBucketSources${getCacheQueryString('clearCache', '?')}`,
                    method: 'GET',
                },
                accessToken,
            );

            if (browserCache.useBrowserCache && dataCacheKey) {
                CacheUtils.setStoredDataByKey(dataCacheKey, result, CacheUtils.dataCacheLongExpiresMin);
            }

            return result;
        }
    };
}
