import {
    BrandVariants,
    GriffelStyle,
    Theme,
    createDarkTheme,
    createLightTheme,
    makeStyles,
    shorthands,
    themeToTokensObject,
    tokens,
} from '@fluentui/react-components';
import { generateBrandVariants } from './libs/utils/BrandRampGenerator';

export const semanticKernelBrandRamp: BrandVariants = {
    10: '#08000D',
    20: '#250442',
    30: '#3D0064',
    40: '#510178',
    50: '#621284',
    60: '#73228F',
    70: '#84309A',
    80: '#943FA4',
    90: '#A44FAE',
    100: '#B25EB7',
    110: '#C06FC0',
    120: '#CD80C9',
    130: '#D992D1',
    140: '#E4A4D9',
    150: '#EDB7E1',
    160: '#F4CAE9',
};

export const brandVariants: BrandVariants =
    // Test if the theme main color is set and is a valid hex color
    process.env.REACT_APP_THEME_MAIN_COLOR == null ||
    process.env.REACT_APP_THEME_MAIN_COLOR.trim() === '' ||
    !/^#[0-9A-Fa-f]{6}$/i.test(process.env.REACT_APP_THEME_MAIN_COLOR)
        ? semanticKernelBrandRamp
        : generateBrandVariants(process.env.REACT_APP_THEME_MAIN_COLOR);

export const semanticKernelLightTheme: Theme & { colorMeBackground: string } = {
    ...createLightTheme(brandVariants),
    colorMeBackground: '#e8ebf9',
};

export const semanticKernelDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(brandVariants),
    colorMeBackground: '#2b2b3e',
};

export const getGradientString = () => {
    if (!process.env.REACT_APP_THEME_GRADIENT || process.env.REACT_APP_THEME_GRADIENT.split(',').length !== 5) {
        return '';
    }
    const gradientColours = process.env.REACT_APP_THEME_GRADIENT.split(",");
    const gradientString = `linear-gradient(to bottom, ${gradientColours[0]}, ${gradientColours[1]}, ${gradientColours[2]}, ${gradientColours[3]}, ${gradientColours[4]})`;
    return gradientString;
}

export const customTokens = themeToTokensObject(semanticKernelLightTheme);

export const Breakpoints = {
    xSmall: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 430px)': style };
    },
    small: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 744px)': style };
    },
    medium: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media screen and (max-width: 790px)': style };
    },
    large: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media screen and (max-width: 960px)': style };
    },
};

export const ScrollBarStyles: GriffelStyle = {
    overflowY: 'auto',
    '&:hover': {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground1,
            WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
            visibility: 'visible',
        },
    },
};

export const SharedStyles: Record<string, GriffelStyle> = {
    scroll: {
        height: '100%',
        ...ScrollBarStyles,
    },
    overflowEllipsis: {
        ...shorthands.overflow('hidden'),
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
};

export const useSharedClasses = makeStyles({
    informativeView: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding('80px'),
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingVerticalXL),
        marginTop: tokens.spacingVerticalXXXL,
    },
});

export const useDialogClasses = makeStyles({
    surface: {
        paddingRight: tokens.spacingVerticalXS,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.overflow('hidden'),
        width: '100%',
    },
    paragraphs: {
        marginTop: tokens.spacingHorizontalS,
    },
    innerContent: {
        height: '100%',
        ...SharedStyles.scroll,
        paddingRight: tokens.spacingVerticalL,
    },
    text: {
        whiteSpace: 'pre-wrap',
        textOverflow: 'wrap',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
    },
});
