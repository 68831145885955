import { Button, Tooltip, makeStyles, shorthands } from '@fluentui/react-components';
import { ThumbDislike16Filled, ThumbLike16Filled } from '@fluentui/react-icons';
import { useCallback } from 'react';
import { useChat } from '../../../libs/hooks';
import { IChatMessage, UserFeedback, UserFeedbackType } from '../../../libs/models/ChatMessage';
import { useAppDispatch } from '../../../redux/app/hooks';
import { addUserFeedback } from '../../../redux/features/conversations/conversationsSlice';
import { ThumbDislike16, ThumbLike16 } from '../../shared/BundledIcons';

const useClasses = makeStyles({
    feedbackControls: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    feedbackIcon: {
        ...shorthands.padding('5px'),
    },
});

interface IUserFeedbackProps {
    message: IChatMessage;
    userFeedback?: UserFeedback;
    onFeedbackTypeProvided: () => void;
}

export const UserFeedbackActions: React.FC<IUserFeedbackProps> = ({
    message,
    userFeedback,
    onFeedbackTypeProvided,
}) => {
    const dispatch = useAppDispatch();
    const classes = useClasses();
    const chat = useChat();

    const onUserFeedbackProvided = useCallback(
        async (positive: boolean) => {
            onFeedbackTypeProvided();

            if (message.id) {
                await chat
                    .editMessage(
                        message.chatId,
                        message.id,
                        positive ? UserFeedbackType.Positive : UserFeedbackType.Negative,
                        undefined,
                    )
                    .then((result: UserFeedback) => {
                        dispatch(
                            addUserFeedback({
                                chatId: message.chatId,
                                userFeedback: result,
                            }),
                        );
                    });
            }
        },
        [dispatch, chat, onFeedbackTypeProvided, message],
    );

    const getFeedbackControls = () => {
        if (userFeedback?.type === UserFeedbackType.Positive) {
            return (
                <Tooltip content={'Good response'} relationship="label">
                    <ThumbLike16Filled className={classes.feedbackIcon} />
                </Tooltip>
            );
        } else if (userFeedback?.type === UserFeedbackType.Negative) {
            return (
                <Tooltip content={'Bad response'} relationship="label">
                    <ThumbDislike16Filled className={classes.feedbackIcon} />
                </Tooltip>
            );
        } else {
            return (
                <>
                    <Tooltip content={'Good response'} relationship="label">
                        <Button
                            icon={<ThumbLike16 />}
                            appearance="transparent"
                            aria-label="Edit"
                            onClick={() => {
                                void onUserFeedbackProvided(true);
                            }}
                        />
                    </Tooltip>
                    <Tooltip content={'Bad response'} relationship="label">
                        <Button
                            icon={<ThumbDislike16 />}
                            appearance="transparent"
                            aria-label="Edit"
                            onClick={() => {
                                void onUserFeedbackProvided(false);
                            }}
                        />
                    </Tooltip>
                </>
            );
        }
    };

    return <div className={classes.feedbackControls}>{getFeedbackControls()}</div>;
};
