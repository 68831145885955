import { Button, Textarea, makeStyles, shorthands } from '@fluentui/react-components';
import { useCallback, useEffect, useState } from 'react';
import { useChat } from '../../../libs/hooks';
import { IChatMessage, UserFeedback } from '../../../libs/models/ChatMessage';
import { customTokens } from '../../../styles';

const useClasses = makeStyles({
    feedbackTextarea: {
        width: '100%',
        boxSizing: 'border-box',
        resize: 'none',
        minWidth: '500px',
    },
    feedbackThankyou: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100px',
        minWidth: '500px',
    },
    feedbackThankyouHide: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '0',
        opacity: '0',
        ...shorthands.transition('all', '0.5s', 'ease'),
    },
    feedbackButton: {
        textAlign: 'right',
        marginTop: customTokens.spacingVerticalM,
    },
});

interface IUserFeedbackProps {
    message: IChatMessage;
    userFeedback?: UserFeedback;
    onFeedbackCommentProvided: () => void;
}

export const UserFeedbackComment: React.FC<IUserFeedbackProps> = ({
    message,
    userFeedback,
    onFeedbackCommentProvided,
}) => {
    const classes = useClasses();
    const chat = useChat();

    const [feedbackComment, setFeedbackComment] = useState<string | undefined>();
    const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);
    const [startHide, setStartHide] = useState<boolean>(false);

    useEffect(() => {
        if (feedbackSubmitted) {
            const timer = setTimeout(() => {
                setStartHide(true);
                const hideTimer = setTimeout(() => {
                    //onFeedbackCommentProvided();
                    clearTimeout(hideTimer);
                }, 600);
                clearTimeout(timer);
            }, 3000);
        }
    }, [feedbackSubmitted, onFeedbackCommentProvided]);

    const onUserFeedbackProvided = useCallback(async () => {
        setFeedbackSubmitted(true);
        if (message.id && userFeedback) {
            await chat.editMessage(message.chatId, message.id, userFeedback.type, feedbackComment);
        }
    }, [message, userFeedback, chat, feedbackComment]);

    const getFeedbackContent = () => {
        if (feedbackSubmitted) {
            return (
                <div className={startHide ? classes.feedbackThankyouHide : classes.feedbackThankyou}>
                    <span>Thank you for your feedback</span>
                </div>
            );
        } else {
            return (
                <>
                    <Textarea
                        placeholder="Tell us more..."
                        onChange={(_event, data) => {
                            setFeedbackComment(data.value);
                        }}
                        className={classes.feedbackTextarea}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && !event.shiftKey) {
                                event.preventDefault();
                                void onUserFeedbackProvided();
                            }
                        }}
                    />
                    <div className={classes.feedbackButton}>
                        <Button
                            disabled={!feedbackComment}
                            appearance="primary"
                            onClick={() => {
                                void onUserFeedbackProvided();
                            }}
                        >
                            Submit feedback
                        </Button>
                    </div>
                </>
            );
        }
    };
    return <div>{getFeedbackContent()}</div>;
};
